import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { AdSchedule } from '@jwp/ott-common/types/ad-schedule';
import type { PlaylistItem } from '@jwp/ott-common/types/playlist';
import { useConfigStore } from '@jwp/ott-common/src/stores/ConfigStore';
import { deepCopy } from '@jwp/ott-common/src/utils/collection';
import { logDev, testId } from '@jwp/ott-common/src/utils/common';
import useEventCallback from '@jwp/ott-hooks-react/src/useEventCallback';
import useOttAnalytics from '@jwp/ott-hooks-react/src/useOttAnalytics';
import { attachAnalyticsParams } from '@jwp/ott-common/src/utils/analytics';
import env from '@jwp/ott-common/src/env';

import type { JWPlayer } from '../../../types/jwplayer';
import { addScript } from '../../utils/dom';

import styles from './Player.module.scss';

type Props = {
  feedId?: string;
  item: PlaylistItem;
  startTime?: number;
  autostart?: boolean;
  adsData?: AdSchedule;
  onReady?: (player?: JWPlayer) => void;
  onPlay?: () => void;
  onPause?: () => void;
  onComplete?: () => void;
  onUserActive?: () => void;
  onUserInActive?: () => void;
  onBeforePlay?: () => void;
  onFirstFrame?: () => void;
  onRemove?: () => void;
  onNext?: () => void;
  onPlaylistItem?: () => void;
  onPlaylistItemCallback?: (item: PlaylistItem) => Promise<undefined | PlaylistItem>;
};

type KilkayaVideo = {
  setField: (field: string, value: string | number) => void;
  load: () => void;
  start: (x:string) => void;
  pause: () => void;
  resume: () => void;
  stop: () => void;
  getLogData: () => any;
};

const Player: React.FC<Props> = ({
  item,
  adsData,
  onReady,
  onPlay,
  onPause,
  onComplete,
  onUserActive,
  onUserInActive,
  onBeforePlay,
  onFirstFrame,
  onRemove,
  onPlaylistItem,
  onPlaylistItemCallback,
  onNext,
  feedId,
  startTime = 0,
  autostart,
}: Props) => {
  const playerElementRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<JWPlayer>();
  const loadingRef = useRef(false);
  const [libLoaded, setLibLoaded] = useState(!!window.jwplayer);
  const startTimeRef = useRef(startTime);
  const [kilkayaVideo, setKilkayaVideo] = useState<KilkayaVideo | null>(null);

  const setPlayer = useOttAnalytics(item, feedId);

  const { settings } = useConfigStore((s) => s);

  const playerId = settings.playerId;
  const playerLicenseKey = settings.playerLicenseKey;

  const extendedOnPlay = () => {
    if (onPlay) {
      onPlay();
    }

    if (kilkayaVideo) {
      kilkayaVideo.resume();
    }
  };

  const extendedOnPause = () => {
    if (onPause) {
      onPause();
    }

    if (kilkayaVideo) {
      kilkayaVideo.pause();
    }
  };

  const extendedOnComplete = () => {
    if (onComplete) {
      onComplete();
    }

    if (kilkayaVideo) {
      kilkayaVideo.stop();
    }
  };

  const onAdStarted = (event: any) => {
    if (kilkayaVideo) {
      const adType = event.adposition === 'pre' ? 'preroll' : event.adposition === 'mid' ? 'midroll' : 'postroll';
      kilkayaVideo.setField("roll", adType);
    }
  };

  const extendedOnReady = () => {
    if(onReady){
      onReady(playerRef.current)
    }
    initKilkayaTracking();
  };

  const handleBeforePlay = useEventCallback(onBeforePlay);
  const handlePlay = useEventCallback(extendedOnPlay);
  const handlePause = useEventCallback(extendedOnPause);
  const handleComplete = useEventCallback(extendedOnComplete);
  const handleUserActive = useEventCallback(onUserActive);
  const handleUserInactive = useEventCallback(onUserInActive);
  const handleFirstFrame = useEventCallback(onFirstFrame);
  const handleRemove = useEventCallback(onRemove);
  const handlePlaylistItem = useEventCallback(onPlaylistItem);
  const handlePlaylistItemCallback = useEventCallback(onPlaylistItemCallback);
  const handleNextClick = useEventCallback(onNext);
  const handleReady = useEventCallback(extendedOnReady);
  const handleAdStarted = useEventCallback((event) => onAdStarted(event));

  const attachEvents = useCallback(() => {
    playerRef.current?.on('ready', handleReady);
    playerRef.current?.on('beforePlay', handleBeforePlay);
    playerRef.current?.on('complete', handleComplete);
    playerRef.current?.on('play', handlePlay);
    playerRef.current?.on('pause', handlePause);
    playerRef.current?.on('userActive', handleUserActive);
    playerRef.current?.on('userInactive', handleUserInactive);
    playerRef.current?.on('firstFrame', handleFirstFrame);
    playerRef.current?.on('remove', handleRemove);
    playerRef.current?.on('playlistItem', handlePlaylistItem);
    playerRef.current?.on('nextClick', handleNextClick);
    playerRef.current?.setPlaylistItemCallback(handlePlaylistItemCallback);
    playerRef.current?.on('adStarted', handleAdStarted);
  }, [
    handleReady,
    handleBeforePlay,
    handleComplete,
    handlePlay,
    handlePause,
    handleUserActive,
    handleUserInactive,
    handleFirstFrame,
    handleRemove,
    handlePlaylistItem,
    handleNextClick,
    handlePlaylistItemCallback,
    handleAdStarted,
  ]);

  const detachEvents = useCallback(() => {
    playerRef.current?.off('ready');
    playerRef.current?.off('beforePlay');
    playerRef.current?.off('complete');
    playerRef.current?.off('play');
    playerRef.current?.off('pause');
    playerRef.current?.off('userActive');
    playerRef.current?.off('userInactive');
    playerRef.current?.off('firstFrame');
    playerRef.current?.off('adStarted');
  }, []);

  const initKilkayaTracking = () => {
    if (window && window.kilkaya && playerRef.current) {
      // const originUrl = window.location.origin;

      const item = playerRef.current?.getPlaylistItem();
      const published = (() => {
        const time = item?.pubdate*1000 ?? new Date().getTime();
        return new Date(time).toISOString();
      })();

      const url = item.mediaid ? `${window.location.origin}/m/${item.mediaid}` : window.location.href;

      const video = window.kilkaya.getVideo(url, false);
      video.setField("title", item.title);
      video.setField("image", item.image || '');
      video.setField("duration", playerRef.current?.getDuration() || 0);
      item.tags?.split(',').forEach(tag => video.setField("tag", tag));
      video.setField("published", published);
      video.setField("login", window.Zephr?.accessDetails.isAuthenticated ? 1 : 0);
      video.setField("subscriber", (window.Zephr?.accessDetails.activeProducts || []).length > 0 ? 1 : 0);
      video.load();
      video.start("auto");

      setKilkayaVideo(video);
    }
  };

  useEffect(() => {
    if (!window.jwplayer && !loadingRef.current) {
      loadingRef.current = true;

      const scriptUrl = `${env.APP_API_BASE_URL}/libraries/${playerId}.js`;

      addScript(scriptUrl).then(() => {
        setLibLoaded(true);
        loadingRef.current = false;
      });
    }
  }, [playerId]);

  useEffect(() => {
    // Update the startTimeRef each time the startTime changes
    startTimeRef.current = startTime;
  }, [startTime]);

  useEffect(() => {
    const loadPlaylist = () => {
      if (!item || !playerRef.current) {
        return;
      }

      const currentItem = playerRef.current?.getPlaylistItem() as unknown as PlaylistItem | null;

      // We already loaded this item
      if (currentItem && currentItem.mediaid === item.mediaid) {
        logDev('Calling loadPlaylist with the same item, check the dependencies');
        return;
      }

      // Update autostart parameter
      if (typeof autostart !== 'undefined') {
        playerRef.current?.setConfig({ autostart });
      }

      // Load new item
      playerRef.current.load([deepCopy({ ...item, starttime: startTimeRef.current, feedid: feedId })]);
    };

    const initializePlayer = () => {
      if (!window.jwplayer || !playerElementRef.current) return;

      playerRef.current = window.jwplayer(playerElementRef.current) as JWPlayer;

      // Inject user_id and profile_id into the CDN analytics
      // @todo this currently depends on stores
      attachAnalyticsParams(item);

      // Player options are untyped
      const playerOptions: { [key: string]: unknown } = {
        advertising: {
          ...adsData,
          // Beta feature
          showCountdown: true,
        },
        timeSlider: {
          showAdMarkers: false,
        },
        aspectratio: false,
        controls: true,
        displaytitle: false,
        displayHeading: false,
        displaydescription: false,
        floating: {
          mode: 'never',
        },
        height: '100%',
        mute: false,
        playbackRateControls: true,
        pipIcon: 'disabled',
        playlist: [deepCopy({ ...item, starttime: startTimeRef.current, feedid: feedId })],
        repeat: false,
        cast: {},
        stretching: 'uniform',
        width: '100%',
      };

      // Only set the autostart parameter when it is defined or it will override the player.defaults autostart setting
      if (typeof autostart !== 'undefined') {
        playerOptions.autostart = autostart;
      }

      // Set the license key if provided
      if (playerLicenseKey) {
        playerOptions.key = playerLicenseKey;
      }

      playerRef.current.setup(playerOptions);

      setPlayer(playerRef.current);
      attachEvents();
    };

    if (playerRef.current) {
      return loadPlaylist();
    }

    if (libLoaded) {
      initializePlayer();
    }
  }, [libLoaded, item, detachEvents, attachEvents, playerId, setPlayer, autostart, adsData, playerLicenseKey, feedId]);

  const finishVideo = () => {
    if(window.kilkaya && kilkayaVideo) {
      window.kilkaya.finishVideo(kilkayaVideo);
    }
  }
  useEffect(() => {
    return () => {
      finishVideo();
      if (playerRef.current) {
        // Detaching events before component unmount
        detachEvents();
        playerRef.current.remove();
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detachEvents]);

  return (
    <div className={styles.container} data-testid={testId('player-container')}>
      <div ref={playerElementRef} />
    </div>
  );
};

export default Player;
